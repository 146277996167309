import React, { Component } from 'react';
import DropDownMenu from 'material-ui/DropDownMenu';
import 'bootstrap/dist/css/bootstrap.css';
import { LinkMemoized as Link } from '../utils/FormsComponents';
import ConfigurationIcon from './img/ic-settings.svg';
import ExitIcon from './img/ic-exit.svg';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';
import IcoCoin from '../utils/img/ic-pontos.svg';
import IcoTag from '../utils/img/ic-tags.svg';
import EditUser from '../utils/img/ic-editar.svg';
import IconMenu from 'material-ui/IconMenu';
import { DialogProgressIndeterminated } from '../utils/DailogComponentes';
import { browserHistory } from 'react-router';
import { DialogYesNo } from '../utils/DailogComponentes';
import { DialogAlert } from '../utils/DailogComponentes';
import UserApi from '../../Logicas/UserApi';
import * as DashBoardApi from '../../Logicas/DashBoardApi';
import DialogTerm from '../utils/dialogs/DialogTerm';
import DialogReport from '../utils/dialogs/DialogReport';
import { style } from '../../style';
import { MenuItemStiled } from '../utils/utils_styled';
import teamSettings from '../utils/TeamsSettings/generalSettings';
import fullNameShorten from '../utils/fullNameShorten';
import DialogReportEmailForm from '../publication/dialog/DialogReportEmail/DialogReportEmail';
require('./nav-header.css');
require('./img/ic-menu.png');

class DashBord extends Component {
  constructor() {
    super();
    this.state = { userimage: '', home_click: true, hasApplications: false };
    this.logout = this.logout.bind(this);
    this.cancel = this.cancel.bind(this);
    this.redirectedit = this.redirectedit.bind(this);
  }

  componentWillMount() {
    this.props.getLoggedUserData();

    const {
      hasCourses,
      hasEvents,
      hasTools,
      hasStore,
      hasHistorical,
      hasGenderFilter,
      hasRecaptcha,
      hasExternalUsers,
      hasApplications
    } = teamSettings();
    this.setState({
      hasCourses,
      hasEvents,
      hasTools,
      hasStore,
      hasHistorical,
      hasGenderFilter,
      hasRecaptcha,
      hasExternalUsers,
      hasApplications
    });
  }
  logout() {
    this.setState({ visibleyesno: true });
  }
  cancel() {
    this.setState({ visibleyesno: false });
  }
  logouttrue() {
    localStorage.clear();
    browserHistory.push('/');
  }
  redirecthome() {
    browserHistory.push('/home');
  }

  redirectedit() {
    this.props.editUser(localStorage.getItem('Userid'));
  }
  redirecttag() {
    browserHistory.push('/tags/list');
  }
  redirectstore() {
    browserHistory.push('newstore');
  }

  render() {
    const textsector = {
      'font-family': 'Rubik',
      ' font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#acacac',
      'min-height': '0px'
    };
    const textemail = {
      height: '12px',
      'font-family': 'Rubik',
      ' font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#acacac',
      'margin-top': '2px'
    };

    const textescore = {
      'font-family': 'Rubik',
      ' font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#efc75e',
      'min-height': '0px'
    };
    const textconfigmenu = {
      height: '12px',
      'font-family': 'Rubik',
      ' font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#acacac',
      cursor: 'pointer'
    };
    const textedit = {
      height: '12px',
      'font-family': 'Rubik',
      ' font-size': '12px',
      'font-weight': 'normal',
      'font-style': 'normal',
      'font-stretch': 'normal',
      'line-height': 'normal',
      'letter-spacing': 'normal',
      'text-align': 'center',
      color: '#acacac',
      'margin-top': '15px'
    };
    let roles = JSON.parse(localStorage.getItem('UserRoles'));
    let user_roles = roles.map(function(role) {
      return role.name;
    });
    const teamName = style.CurrencyName.name.split('/');
    return (
      <MuiThemeProvider>
        <div className="nav-header-container">
          <ul className="ul-actions-container">
            <li>
              <a>
                <img
                  style={{
                    height: '50px',
                    width: '60px',
                    objectFit: 'contain'
                  }}
                  alt="logo"
                  onClick={
                    user_roles.includes('store') ? null : this.redirecthome
                  }
                  src={style.fileUrl.logo}
                  className="logo-menu"
                />
              </a>
            </li>
            {(user_roles.includes('admin') ||
              user_roles.includes('manager')) && (
              <Link
                select={this.props.route === '/home' ? true : false}
                to="/home"
                name="DashBoard"
              />
            )}
            {user_roles.includes('admin') ||
            user_roles.includes('speaker') ||
            user_roles.includes('manager') ? (
              <Link
                select={this.props.route === '/post/publication' ? true : false}
                to="/post/publication"
                name="Publicações"
              />
            ) : null}
            {(user_roles.includes('admin') ||
              user_roles.includes('academy') ||
              user_roles.includes('manager')) &&
            style.teamName.name !== 'Lwart' ? (
              <Link
                select={this.props.route === '/course/list' ? true : false}
                to="/course/list"
                name={
                  this.state.hasCourses ? this.state.hasCourses.value : 'Cursos'
                }
              />
            ) : null}

            {user_roles.includes('admin') ||
            user_roles.includes('personal_department') ||
            user_roles.includes('manager') ? (
              <Link
                select={this.props.route === '/user/list' ? true : false}
                to="/user/list"
                name="Usuários"
              />
            ) : null}

            {!this.state.hasStore.display ? null : user_roles.includes(
                'admin'
              ) || user_roles.includes('store') ? (
              <Link
                select={this.props.route === '/store/products' ? true : false}
                to="/store/products"
                name={'Loja'}
              />
            ) : null}
            {user_roles.includes('admin') ? (
              <Link
                select={this.props.route === '/files/folders' ? true : false}
                to="/files/folders"
                name="Arquivos"
              />
            ) : null}

            {!user_roles.includes('store') ? (
              <Link
                select={this.props.route === '/calendar' ? true : false}
                to="/calendar"
                name={
                  this.state.hasEvents ? this.state.hasEvents.value : 'Eventos'
                }
              />
            ) : null}

            {user_roles.includes('admin') || user_roles.includes('manager') ? (
              <Link
                select={this.props.route === '/tags/list' ? true : false}
                to="/tags/list"
                name="Tags"
              />
            ) : null}

            {user_roles.includes('admin') && (
              <Link
                select={this.props.route === '/sector/list' ? true : false}
                to="/sector/list"
                name="Setor"
              />
            )}

            {!this.state.hasApplications.display ? null : user_roles.includes(
                'admin'
              ) ? (
              <Link
                select={this.props.route === '/app/list' ? true : false}
                to="/app/list"
                name={this.state.hasApplications.value}
              />
            ) : null}

            {this.state.hasTools.display ? (
              user_roles.includes('admin') ? (
                <Link
                  select={this.props.route === '/tools/list' ? true : false}
                  to="/tools/list"
                  name={
                    this.state.hasTools
                      ? this.state.hasTools.value
                      : 'Ferramentas'
                  }
                />
              ) : null
            ) : null}

            {!this.state.hasHistorical
              ? null
              : (user_roles.includes('admin') ||
                  user_roles.includes('manager')) && (
                  <Link
                    select={
                      this.props.route === '/historical/list' ? true : false
                    }
                    to="/historical/list"
                    name={`Histórico de ${teamName[1]}`}
                  />
                )}
          </ul>

          <ul className="ul-actions-user-config">
            <MenuItemStiled>
              <DropDownMenu
                anchorOrigin={{ horizontal: 'left' }}
                value={1}
                className="DropDown"
              >
                <MenuItem
                  value={1}
                  label={
                    <label className="text-name-user">
                      {fullNameShorten(this.props.loggedUserData.userName, 1)}
                    </label>
                  }
                  disabled={true}
                  className="dropdowperfil"
                  value={1}
                >
                  <MenuItem disabled={true}>
                    <Avatar
                      style={{
                        position: 'absolute',
                        left: '42%',
                        marginTop: '10px'
                      }}
                      src={this.props.loggedUserData.userImage}
                    />
                  </MenuItem>
                  <MenuItem
                    style={{ marginTop: '10px', 'min-height': '0px' }}
                    disabled={true}
                  >
                    <div style={textsector}>
                      {this.props.loggedUserData.userName}
                    </div>
                  </MenuItem>
                  <MenuItem
                    style={{ marginTop: '10px', 'min-height': '0px' }}
                    disabled={true}
                  >
                    <div style={textsector}>
                      {this.props.loggedUserData.userSector}
                    </div>
                  </MenuItem>
                  <MenuItem
                    style={{ marginTop: '10px', 'min-height': '0px' }}
                    disabled={true}
                  >
                    <div style={textemail}>
                      {this.props.loggedUserData.userEmail}
                    </div>
                  </MenuItem>
                  <MenuItem
                    style={{ marginTop: '15px', 'min-height': '0px' }}
                    disabled={true}
                  >
                    <div style={textescore}>
                      <img
                        style={{ marginRight: '5px', marginBottom: '10px' }}
                        src={IcoCoin}
                        alt="ícone de moeda"
                      />
                      {this.props.loggedUserData.userScore} pontos
                    </div>
                  </MenuItem>
                </MenuItem>
                {user_roles.includes('admin') ||
                user_roles.includes('personal_department') ? (
                  <MenuItem
                    onClick={this.redirectedit}
                    className="item"
                    primaryText={
                      <div className="item" style={textedit}>
                        Editar informações{' '}
                        <img
                          style={{ marginLeft: '2px' }}
                          src={EditUser}
                          alt="editar"
                        />
                      </div>
                    }
                    style={{
                      'border-top-style': 'solid 1px #979797',
                      border: '1px',
                      courser: 'pointer'
                    }}
                  />
                ) : null}
              </DropDownMenu>
            </MenuItemStiled>
            {user_roles.includes('admin') ||
            user_roles.includes('personal_department') ? (
              <MenuItemStiled>
                <IconMenu
                  iconButtonElement={
                    <img
                      alt="ícone configurações"
                      className="icons-menu"
                      src={ConfigurationIcon}
                    />
                  }
                >
                  <MenuItem
                    onClick={this.props.openDiagloTerm}
                    style={textconfigmenu}
                    value="1"
                  >
                    <label
                      onClick={this.props.openDiagloTerm}
                      style={{
                        cursor: 'pointer',
                        lineHeight: '48px',
                        marginBottom: 0
                      }}
                    >
                      Gerenciar Termo de uso <img src={IcoTag} alt="hashtag" />{' '}
                    </label>
                  </MenuItem>
                  <MenuItem
                    onClick={browserHistory.push.bind(this, '/user/import')}
                    style={textconfigmenu}
                    value="1"
                  >
                    <label
                      onClick={browserHistory.push.bind(this, '/user/import')}
                      style={{
                        cursor: 'pointer',
                        lineHeight: '48px',
                        marginBottom: 0
                      }}
                    >
                      Importar Usuários <img src={IcoTag} alt="hashtag" />{' '}
                    </label>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.props.openReportEmailDialog('usageReport')
                    }
                    style={textconfigmenu}
                    value="1"
                  >
                    <label
                      style={{
                        lineHeight: '48px',
                        marginBottom: 0
                      }}
                    >
                      Relatório de uso <img src={IcoTag} alt="hashtag" />{' '}
                    </label>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      this.props.openReportEmailDialog('accessReport')
                    }
                    style={textconfigmenu}
                    value="1"
                  >
                    <span
                      style={{
                        cursor: 'pointer',
                        lineHeight: '48px',
                        marginBottom: 0
                      }}
                    >
                      Relatório de acessos <img src={IcoTag} alt="hashtag" />{' '}
                    </span>
                  </MenuItem>
                  {(style.teamName.name === 'PetroRio' ||
                    style.teamName.name === 'sample') && (
                    <MenuItem style={textconfigmenu} value="1">
                      <span
                        style={{
                          cursor: 'pointer',
                          lineHeight: '48px',
                          marginBottom: 0
                        }}
                        onClick={() => browserHistory.push('/items-menu')}
                      >
                        Itens de menu <img src={IcoTag} alt="hashtag" />{' '}
                      </span>
                    </MenuItem>
                  )}
                </IconMenu>
              </MenuItemStiled>
            ) : null}
            <MenuItemStiled onClick={this.logout}>
              <img src={ExitIcon} alt="sair" />
            </MenuItemStiled>
          </ul>
        </div>
        <DialogYesNo
          noclick={this.cancel}
          yesclick={this.logouttrue}
          message="Tem certeza que deseja sair do sistema ?"
          visible={this.state.visibleyesno}
        />
        <DialogAlert
          closeDialog={
            typeof this.props.error.okFunction === 'function'
              ? () => {
                  this.props.error.okFunction();
                  this.props.closeDialog();
                }
              : this.props.closeDialog
          }
          visible={this.props.error.visible}
          message={this.props.error.message}
          submessage={this.props.error.submessage}
          fileList={this.props.error.fileList}
        />
        {this.props.progress_indeterminate === true ? (
          <DialogProgressIndeterminated />
        ) : (
          undefined
        )}
        <DialogTerm visible={this.props.alert_term_use.visible} />
        {this.props.dialog_report.visible === true ? (
          <DialogReport visible={this.props.dialog_report.visible} />
        ) : null}
        <DialogReportEmailForm />
      </MuiThemeProvider>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    error: state.alert_erro_geral,
    dialogprogress: state.dialogprogress,
    progress_indeterminate: state.progress_indeterminate,
    alert_term_use: state.alert_term_use,
    dialog_report: state.dialog_report,
    loggedUserData: state.dashboard.loggedUserData,
    dialog_report_email: state.dialog_report_email
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () => {
      dispatch({ type: 'CLOSE_ERROR', alert: { visible: false, message: '' } });
    },
    editUser: (value, id) => {
      dispatch(UserApi.editUser(value));
    },
    openDiagloTerm: () => {
      dispatch({ type: 'OPEN_DIALOG_TERM', alert: { visible: true } });
    },
    openDialogReport: () => {
      dispatch({ type: 'SHOW_DIALOG_REPORT', data: { visible: true } });
    },
    getLoggedUsersReport: () => {
      dispatch(UserApi.getLoggedUsersReport());
    },
    openReportEmailDialog: (kind) => {
      dispatch({
        type: 'OPEN_REPORT_EMAIL_MODAL',
        payload: { kind, startDate: '', endDate: '' }
      });
    },
    getLoggedUserData: () => {
      dispatch(DashBoardApi.getLoggedUserData());
    }
  };
};

const DashBoardMemo = React.memo(DashBord);

const DashBoardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashBoardMemo);

export default DashBoardContainer;
