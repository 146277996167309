import React, { Component } from 'react';
import { connect } from 'react-redux';
import RemoveItemIco from '../../../../utils/img/ic-excluir.svg';
import EditItemIco from '../../../../utils/img/ic-editar.svg';
import DownloadIco from './Icons/DownloadIcon';
import LinkIco from './Icons/LinkIcon';
import * as SectionApi from '../../../../../Logicas/SectionApi';

import * as S from './GridCard.styles';

export class GridCard extends Component {
  constructor() {
    super();
    this.state = {
      card: {
        image: ''
      },
      sectionId: '',
      toolId: ''
    };
  }

  componentDidMount() {
    const { external_link_section_id: sectionId, id: toolId } = this.props.data;
    this.setState({ card: this.props.data, sectionId, toolId });
  }

  componentWillReceiveProps() {
    this.setState({ card: this.props.data });
  }

  render() {
    return (
      <S.GridCard>
        <S.GridCardHeader>
          {this.props.data.kind === 'url' ? (
            this.props.data.image.url === null ? (
              <S.GridCardFile className="link-icon">
                <LinkIco />
              </S.GridCardFile>
            ) : (
              <S.GridCardImage
                src={this.props.visible ? this.props.data.image.url : ''}
                alt={this.props.data.name.substring(0, 20)}
              />
            )
          ) : (
            <S.GridCardFile className="download-icon">
              <DownloadIco />
            </S.GridCardFile>
          )}
        </S.GridCardHeader>
        <S.GridcardBody>
          <S.GridCardTitle>{this.props.data.name}</S.GridCardTitle>
          <a
            href={
              this.props.data.kind === 'url'
                ? this.props.data.url
                : this.props.data.file.url
            }
            rel="noopener noreferrer"
            target="_blank"
            download={this.props.data.kind === 'url' ? false : true}
          >
            <S.GridCardLinkFile>
              {this.props.data.kind === 'url' ? <LinkIco /> : <DownloadIco />}
              <S.GridCardType>
                {this.props.data.kind === 'url' ? 'link' : 'arquivo'}
              </S.GridCardType>
            </S.GridCardLinkFile>
          </a>
          <S.GridCardActions>
            <S.GridCardActionItem
              onClick={() =>
                this.props.editResource(
                  this.state.sectionId,
                  this.props.data.id
                )
              }
            >
              <S.GridCardIcon src={EditItemIco} />
              <S.GridCardText>Editar</S.GridCardText>
            </S.GridCardActionItem>
            <S.GridCardActionItem
              onClick={() =>
                this.props.deleteResource(
                  this.state.sectionId,
                  this.props.data.id
                )
              }
            >
              <S.GridCardIcon src={RemoveItemIco} />
              <S.GridCardText>Apagar</S.GridCardText>
            </S.GridCardActionItem>
          </S.GridCardActions>
        </S.GridcardBody>
      </S.GridCard>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sectionList: state.section.sectionList,
    alert: state.dialog
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editResource: async (sectionId, toolId) => {
      await dispatch(SectionApi.editTool(sectionId, toolId));
    },
    deleteResource: async (sectionId, toolId) => {
      await dispatch(SectionApi.deleteTool(sectionId, toolId));
    }
  };
};
const ApplicationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GridCard);

export default ApplicationListContainer;
