import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import InfiniteScroll from 'react-infinite-scroller';
import { reduxForm } from 'redux-form';
import { Toggle } from 'material-ui';
import { v1 as uuidv1 } from 'uuid';
import SectorApi from '../../Logicas/SectorApi';
import TagApi from '../../Logicas/TagApi';
import {
  Table,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import { Body } from '../utils/BodyComponente';
import {
  DropDownDefaultOnlyOne,
  DatePickerForm
} from '../utils/FormsComponents';
import ComponentHeader from '../utils/ComponenteHeader';
import EditSelectInput from './Components/EditSelectInput/EditSelectInput';
import RangeInput from './Components/RangeInput/RangeInput';
import { TextFieldForm } from '../utils_form/FormComponents';
import { required } from '../validation/FormValidation';
import { style } from '../../style';

const monthOptions = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

const activityOptions = [
  "Pintor",
  "Consumidor",
  "Quero me formar pintor",
  "Balconista",
  "Arquiteto / Decorador"
];

const filterOptions = [
  'Mês do Aniversário',
  'Gênero',
  // 'Data de contratação',
  'Pontuação',
  'Setor',
];

const filterOptionsWithActivity = [
  'Mês do Aniversário',
  'Gênero',
  // 'Data de contratação',
  'Pontuação',
  'Setor',
  'Atividade'
]

require('./tag.css');

class TagEdit extends Component {
  constructor() {
    super();
    this.state = { selected: '', available: false };
  }

  componentWillMount() {
    const { initialValues } = this.props;
    if (!Object.keys(initialValues).length) {
      browserHistory.push('/tags/list');
      return null;
    }
  }

  async componentDidMount() {
    const {
      getSectorNamesList,
      getUsersAssociation,
      initialValues: { id: tagId, valide: available }
    } = this.props;
    this.setState({ available });
    await getSectorNamesList();
    await getUsersAssociation(tagId);
  }

  getFilter = (event, index, value) => {
    this.setState({ selected: value });
  };

  updateTag = () => {
    this.props.editTag(
      this.props.initialValues.id,
      this.props.name,
      this.props.description,
      this.state.available,
      this.state.selected,
      this.props.mergeTagsValue
    );
  };

  filterUserTagsByScoreWithUser = ({min, max}) => {
    this.props.filterUserTagsByScore({min, max}, this.props.initialValues.id, 1)
  }

  render() {
    const styles = {
      block: {
        maxWidth: 250
      },
      toggle: {
        marginBottom: 16
      },
      thumbOff: {
        backgroundColor: '#ffcccc'
      },
      trackOff: {
        backgroundColor: '#ff9d9d'
      },
      thumbSwitched: {
        backgroundColor: '#1bab60'
      },
      trackSwitched: {
        backgroundColor: '#79dba9'
      },
      labelStyle: {
        color: 'red'
      }
    };

    const {
      filterUserTagsByBirthday,
      filterUserTagsByGender,
      filterUserTagsBySector,
      filterUserTagsByScore,
      filterUserTagsByHiringDate,
      filterUserTagsByActivity
    } = this.props;

    const convertFunctionByStateSelected = (type) => {
      switch(type){
        case 'Atividade':
          filterUserTagsByActivity(this.props.actualfilter, this.props.initialValues.id, this.props.pagination.next_page);
          break;
        case 'Gênero':
          filterUserTagsByGender(this.props.actualfilter, this.props.initialValues.id, this.props.pagination.next_page);
          break;
        case 'Pontuação':
          filterUserTagsByScore(this.props.actualfilter, this.props.initialValues.id, this.props.pagination.next_page);
          break;
        case 'Setor':
          filterUserTagsBySector(this.props.actualfilter, this.props.initialValues.id, this.props.pagination.next_page);
          break;
        case 'Mês do Aniversário':
          filterUserTagsByBirthday(this.props.actualfilter, this.props.initialValues.id, this.props.pagination.next_page);
          break;
        default:
          console.log("Erro: Nenhum filtro foi identificado!");
          break;
      }
    }

    return (
      <div>
        <ComponentHeader
          actions={[
            {
              name: 'Mesclar Tags',
              action: browserHistory.push.bind(this, '/tags/merger')
            }
          ]}
          name="Editar tags"
          nameaction="Salvar"
          onClick={this.updateTag}
          enablesubmit={!this.props.valid}
        />
        <Body>
          <div className="row">
            <div className="col-md-3">
              <TextFieldForm
                name="name"
                floatingLabelText="Nome da Tag"
                validations={[required]}
              />
            </div>
            <div
              style={{ marginTop: '36px', marginRight: '-50px' }}
              className="offset-md-5 col-md-3"
            >
              <span className="user-association">
                {' '}
                {this.props.initialValues.users_tag} usuários associados
              </span>
            </div>
            <div
              style={{ marginTop: '40px', marginLeft: '-35px' }}
              className="col-md-1"
            >
              <Toggle
                thumbStyle={styles.thumbOff}
                trackStyle={styles.trackOff}
                thumbSwitchedStyle={styles.thumbSwitched}
                trackSwitchedStyle={styles.trackSwitched}
                labelStyle={styles.labelStyle}
                defaultToggled={this.props.initialValues.valide}
                onToggle={() =>
                  this.setState({ available: !this.state.available })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextFieldForm name="description" floatingLabelText="Descrição" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3" style={{ marginRight: '18px' }}>
              <DropDownDefaultOnlyOne
                multiple={false}
                floatingLabelText="Associar usuários por"
                value={this.state.selected}
                onChange={this.getFilter}
                name="kind_filter"
                array={
                  style.teamName.name === 'Pintou Parceria Suvinil' ||  style.teamName.name === 'sample' ? 
                    filterOptionsWithActivity : filterOptions
                }
              />
            </div>
            <div className="col-md-4">
              {this.state.selected === 'Gênero' && (
                <EditSelectInput
                  floatingLabelText="Selecione o gênero"
                  onChange={(e) => filterUserTagsByGender(e.target.outerText, this.props.initialValues.id, 1)}
                  options={[
                    'Mulher cis',
                    'Mulher trans',
                    'Homem cis',
                    'Homem trans',
                    'Não binário',
                    'Prefere não se identificar',
                    'Outros'
                  ]}
                />
              )}
              {this.state.selected === 'Mês do Aniversário' && (
                <EditSelectInput
                  floatingLabelText="Selecione o mês do aniversário"
                  onChange={(e) => filterUserTagsByBirthday(e.target.outerText, this.props.initialValues.id, 1)}
                  options={monthOptions}
                />
              )}
              {/* {this.state.selected === 'Data de contratação' && (
                <DatePickerForm
                  textFieldStyle={{ marginTop: '12px' }}
                  style={{ marginTop: '12px' }}
                  floatingLabelText="Digite a data de contratação"
                  onChange={(event, hiringDate) => {
                    filterUserTagsByHiringDate(hiringDate, this.props.initialValues.id);
                  }}
                />
              )} */}
              {this.state.selected === 'Pontuação' && (
                <RangeInput filterUserTagsByScore={this.filterUserTagsByScoreWithUser} />
              )}
              {this.state.selected === 'Setor' && (
                <EditSelectInput
                  floatingLabelText="Selecione o setor"
                  onChange={(e) => filterUserTagsBySector(e.target.outerText, this.props.initialValues.id, 1)}
                  options={this.props.sectorList}
                />
              )}

              {this.state.selected === 'Atividade' && (
                <EditSelectInput
                  floatingLabelText="Selecione a atividade"
                  onChange={(e) => filterUserTagsByActivity(e.target.outerText, this.props.initialValues.id, 1)}
                  options={activityOptions}
                />
              )}
            </div>
            <div className="offset-md-1 col-md-3" />
          </div>
          <br />
          <div className="div-body-tag" />
          <div className="row">
            <div className="col-md-6 hiddenOverflow">
              <span className="label-tag-discleimer">
                Lista de usuários atualmente associados ({' '}
                {this.props.paginationTag.total_count} )
              </span>
              <Table height="300px">
                <TableBody displayRowCheckbox={false}>
                  <TableRow>
                    <TableHeaderColumn>Nome</TableHeaderColumn>
                    <TableHeaderColumn>Setor</TableHeaderColumn>
                  </TableRow>
                  <div
                    id="infiniteScrollDiv"
                    style={{
                      overflowY: 'auto !important',
                      overflowX: 'hidden',
                      maxHeight: '240px',
                      minWidth: '490px',
                    }}
                  >
                    <InfiniteScroll
                      pageStart={1}
                      initialLoad={false}
                      loadMore={
                        this.props.paginationTag.next_page === null ? 
                          () => {}
                          : () => this.props.getUsersAssociation(this.props.initialValues.id, this.props.paginationTag.next_page)
                      }
                      hasMore={
                        this.props.paginationTag && (this.props.paginationTag.current_page > 0 && this.props.paginationTag.next_page !== null)
                      }
                      useWindow={false}
                      threshold={20}
                      loader={<div style={{margin:"0 auto", fontSize:"12px", textAlign:"center", marginTop: '1rem'}}>Carregando...</div>}
                    >
                      {this.props.tagUserAssociation.map((user) => (
                        <TableRow displayRowCheckbox={false} key={uuidv1()}>
                          <TableRowColumn>{user.name}</TableRowColumn>
                          <TableRowColumn>{user.sector.name}</TableRowColumn>
                        </TableRow>
                      ))}
                    </InfiniteScroll>
                  </div>
                </TableBody>
              </Table>
            </div>
            <div className="col-md-6 hiddenOverflow">
              <span className="label-tag-discleimer">
                Lista de usuários a serem associados ({this.props.pagination.total_count}{' '})
              </span>
              <Table height="300px">
                <TableBody displayRowCheckbox={false}>
                  <TableRow>
                    <TableHeaderColumn>Nome</TableHeaderColumn>
                    <TableHeaderColumn>Setor</TableHeaderColumn>
                  </TableRow>
                  <div
                    id="infiniteScrollDiv"
                    style={{
                      overflowY: 'auto !important',
                      overflowX: 'hidden',
                      maxHeight: '240px',
                      minWidth: '490px',
                    }}
                  >
                    <InfiniteScroll
                      pageStart={1}
                      initialLoad={false}
                      loadMore={
                        this.props.pagination.next_page === null ? 
                          () => {}
                          : () => convertFunctionByStateSelected(this.state.selected)}
                        hasMore={this.props.pagination && (this.props.pagination.current_page > 0 && this.props.pagination.next_page !== null)}
                      useWindow={false}
                      threshold={20}
                      loader={<div style={{margin:"0 auto", fontSize:"12px", textAlign:"center", marginTop: '1rem'}}>Carregando...</div>}
                    >
                      {
                        this.props.list && this.props.list.map((user) => (
                          <TableRow displayRowCheckbox={false} key={uuidv1()}>
                            <TableRowColumn>{user.name}</TableRowColumn>
                            <TableRowColumn>{user.sector.name}</TableRowColumn>
                          </TableRow>
                        ))
                      }
                    </InfiniteScroll>
                  </div>
                  
                  
                </TableBody>
              </Table>
            </div>
          </div>
        </Body>
      </div>
    );
  }
}

let InitializeFromStateForm = reduxForm({
  form: 'edittag'
})(TagEdit);

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersAssociation: (tagId, page = 1) => {
      dispatch(TagApi.getTagUsersAssociationV2(tagId, page));
    },
    editTag: (id, name, description, available, filter, values) => {
      dispatch(
        TagApi.editTag(id, name, description, available, filter, values)
      );
    },
    getSectorNamesList: () => {
      dispatch(SectorApi.getSectorNamesList());
    },
    filterUserTagsByBirthday: (month, tagId, page = 1) => {
      dispatch(TagApi.filterUserTagsByBirthday(month, tagId, page));
    },
    filterUserTagsByGender: (sex, tagId, page = 1) => {
      dispatch(TagApi.filterUserTagsByGender(sex, tagId, page));
    },
    filterUserTagsBySector: (sector, tagId, page = 1) => {
      dispatch(TagApi.filterUserTagsBySector(sector, tagId, page));
    },
    filterUserTagsByHiringDate: (hiringDate, tagId, page = 1) => {
      dispatch(TagApi.filterUserTagsByHiringDate(hiringDate, tagId, page));
    },
    filterUserTagsByScore: (values, tagId, page = 1) => {
      dispatch(TagApi.filterUserTagsByScore(values, tagId, page));
    },
    filterUserTagsByActivity: (autoDeclared, tagId, page = 1) => {
      dispatch(TagApi.filterUserTagsByActivity(autoDeclared, tagId, page));
    }
  };
};
const selector = formValueSelector('edittag');

InitializeFromStateForm = connect(
  (state) => ({
    initialValues: state.tag.editTag,
    alert: state.dialog,
    list: state.user.user_list,
    actualfilter: state.user.actualfilter,
    pagination: state.user.pagination,
    tagUserAssociation: state.tag.tagUserAssociation,
    paginationTag: state.tag.pagination,
    mergeTagsValue: state.tag.mergeTagsValue,
    filter: selector(state, 'kind_filter'),
    sectorList: state.sectorReducer.sectorNamesList,
    name: selector(state, 'name'),
    description: selector(state, 'description')
  }),
  mapDispatchToProps
)(InitializeFromStateForm);

export default InitializeFromStateForm;
