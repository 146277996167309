
  export const style={
    inpuStyle:{
      colorInput:"#FF4553"
    },
    url:{
      urlClient:"https://astro.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/astro.png"
    },
    CurrencyName:{
      name:"Astrocoin/Astrocoins"
    },
    teamName:{
      name:"astro"
    },
    hasToken: false,
    hostHeader: "astro"
  }
